<template>
  <v-container grid-list-md class="general">
    <v-layout row wrap>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 mb-4 v-if="depositTypes.length > 0">
            <v-card>
              <div class="grey lighten-5 pa-3">
                {{ $t("inventory-settings") }}
              </div>
              <div
                v-for="(depositType, index) in depositTypes"
                :key="'depositType-' + index"
                @click="showDepositType(depositType.id)"
              >
                <v-divider></v-divider>
                <v-layout row wrap py-1 pl-2 pr-3 class="align-items-center">
                  <v-flex xs1 text-xs-center>
                    <font-awesome-icon
                      :icon="transformIcon(depositType.icon)"
                      :color="depositType.color"
                      style="font-size:24px;"
                    />
                  </v-flex>
                  <v-flex xs4>{{ depositType.name }}</v-flex>
                  <v-flex xs3>{{ depositType.description }}</v-flex>
                  <v-flex text-xs-right>
                    <v-btn icon class="hover_opacity">
                      <v-icon
                        small
                        color="grey lighten-1"
                        @click.stop="deleteDepositTypes(depositType.id)"
                        >delete</v-icon
                      >
                    </v-btn>
                  </v-flex>
                </v-layout>
              </div>
            </v-card>
          </v-flex>
          <v-flex xs12 text-xs-center v-if="totalPages > 1">
            <v-pagination
              v-model="page"
              :length="totalPages"
              :total-visible="totalVisible"
              @input="getDepositTypes"
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-btn
      v-if="!isLoading && !warning"
      :to="{
        name: 'deposit_settings_create',
        params: { group_id: groupId, group_plugin_id: groupPluginId },
      }"
      fixed
      dark
      fab
      bottom
      right
      color="blue"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    depositTypes: [],
    page: null,
    totalPages: null,
    totalVisible: 5,
    perPage: 15,
    warning: false,
    isLoading: false,
  }),
  computed: {
    ...mapGetters(["currentUser"]),
    isAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("admin");
      }
    },
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    currentPage() {
      if (this.$route.query.page) {
        return this.$route.query.page;
      }
      return 1;
    },
  },
  mounted() {
    this.getDepositTypes();
  },
  methods: {
    transformIcon(icon) {
      if (!icon) return [];
      return icon.split(" ");
    },
    showDepositType(id) {
      if (!id) return;

      this.$router
        .push({
          name: "deposit_settings_edit",
          params: {
            group_id: this.groupId,
            group_plugin_id: this.groupPluginId,
            deposit_type_id: id,
          },
        })
        .catch(() => {});
    },
    getDepositTypes() {
      this.isLoading = true;

      const params = {
        group_id: this.groupId,
        params: {
          group_plugin_id: this.groupPluginId,
          page: this.currentPage,
          per_page: this.perPage,
        },
      };

      this.$store
        .dispatch("listDepositTypes", params)
        .then((data) => {
          if (data) {
            this.depositTypes = data.data;
            this.totalPages = data.pagination.total_pages;

            this.$router
              .replace({
                query: {
                  page: this.page,
                },
              })
              .catch(() => {});
          }
        })
        .catch((e) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteDepositTypes(id) {
      if (!id) return;

      this.$vuetifyConfirmDialog
        .open(
          this.$tc("confirmation", 1),
          this.$t("deposit-type-deletion-warning"),
          this.$t("cancel"),
          this.$t("confirm"),
        )
        .then((state) => {
          if (state) {
            const params = {
              group_id: this.groupId,
              deposit_type_id: id,
              params: {
                group_plugin_id: this.groupPluginId,
              },
            };
            this.$store.dispatch("deleteDepositTypes", params).then((data) => {
              if (data) {
                this.$store.dispatch("addNotification", {
                  message: this.$t("deposit-type-deleted"),
                });
                this.getDepositTypes();
              }
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.v-card .layout .hover_opacity {
  opacity: 0;
}

.v-card .layout:hover .hover_opacity {
  opacity: 1;
}
</style>
